export const routes = {
  auth: {
    login: '/auth/local',
  },
  users: {
    list: '/users',
    get: '/users/{userId}',
    create: '/users',
    update: '/users/{userId}',
    me: '/users/me',
  },
  companies: {
    list: '/companies',
    get: '/companies/{companyId}',
    create: '/companies',
    update: '/companies/{companyId}',
    delete: '/companies/{companyId}',
  },
  plans: {
    list: '/plans',
  },
  userPassword: {
    forgotPassword: '/users/password/forgot/email/{email}',
    resetPassword: '/users/password/reset/{resetPasswordCode}',
    verifyCode: '/users/password/verify/{resetPasswordCode}',
    changePassword: '/users/password/change',
    checkPassword: '/users/check-password',
  },
};
