import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbCardModule } from '@nebular/theme';
import { ConfirmLogoutComponent } from './confirm-logout.component';

@NgModule({
  declarations: [
    ConfirmLogoutComponent,
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
  ],
})
export class ConfirmLogoutModule { }
