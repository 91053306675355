//#region Imports

import { Component, OnInit } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { SeoService } from './@core/utils';

//#endregion

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  //#region Constructor

  constructor(
    private seoService: SeoService,
    private iconLibraries: NbIconLibraries,
  ) {
    this.iconLibraries.registerFontPack('my-icons', { iconClassPrefix: 'cv' });
  }

  //#endregion

  //#region LifeCycle Events

  public async ngOnInit(): Promise<void> {
    this.seoService.trackCanonicalChanges();
  }

  //#endregion

}
