<nb-card>
    <nb-card-header>
      <span class="span">
        Tem certeza que <br>
        deseja sair?
      </span>
    </nb-card-header>

    <nb-card-body>
      <div class="btn-modal">
        <button nbButton shape="round" size="small" (click)="onCancel()">Cancelar</button>
        <button nbButton shape="round" size="small" class="btn-leave" (click)="onSubmit()">Sair</button>
      </div>
    </nb-card-body>
</nb-card>
