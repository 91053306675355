import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ResetPasswordPayload } from '../../../app/models/payloads/reset-password.payload';
import { UserProxy } from '../../../app/models/proxys/user.proxy';
import { getCrudErrors } from '../../../app/shared/utils/functions';
import { environment } from '../../../environments/environment';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';
import { CustomValidators } from '../../shared/utils/custom-validators';
import { UserService } from './../../services/user/user.service';

@Component({
  selector: 'ngx-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss'],
})
export class EditPasswordComponent implements OnInit {

  //#region Constructor

  constructor(
    protected readonly formBuilder: FormBuilder,
    protected readonly router: Router,
    protected readonly http: HttpAsyncService,
    protected readonly toast: NbToastrService,
    protected readonly userService: UserService,
    protected readonly ref: NbDialogRef<EditPasswordComponent>,
  ) {
    this.formGroup = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      currentPassword: ['', Validators.required],
    }, {
      validators: CustomValidators.mustMatch('newPassword', 'currentPassword'),
    });
  }

  //#endregion

  //#region Public Properties

  @Input()
  public user?: UserProxy;

  @Input()
  public isFirstAcess?: boolean;

  public isLoading: boolean = false;

  public formGroup: FormGroup;

  public equalsPassword?: boolean = false;

  public uperCasePassword?: boolean = false;

  public minLengthPassword?: boolean = false;

  public numberPassword?: boolean = false;

  public letterPassword?: boolean = false;

  public especialCaracterPassword?: boolean = false;

  public completeRegex?: boolean = false;

  public shouldHideCurrentPassword = true;

  public shouldHideNewPassword = true;

  public shouldHideConfirmPassword = true;

  //#endregion

  //#region ngOnInit

  ngOnInit(): void {
    this.isFirstAcess = this.userService.getCurrentUser()?.shouldRequestPasswordChange;
  }

  //#endregion

  //#region Public Methods

  public close(): void {
    this.ref.close();
  }

  public async resetPassword(): Promise<void> {
    if (this.isLoading)
      return;

    this.isLoading = true;

    const payload: ResetPasswordPayload = this.formGroup.getRawValue();

    const url = environment.api.userPassword.changePassword;

    const { error } = await this.http.post<UserProxy>(url, payload);

    this.isLoading = false;

    if (error)
      return void this.toast.danger(getCrudErrors(error)[0], 'Oops...');

    this.toast.success('Senha alterada!', 'Sucesso');

    await this.ref.close();
  }

  public validateEqualsPassword() {
    const payload = this.formGroup.getRawValue();
    if (payload?.newPassword === payload.confirmPassword)
      return this.equalsPassword = true;
    return this.equalsPassword = false;
  }

  public validateNewPassword() {
    const payload = this.formGroup.getRawValue();
    const passwordUpercaseRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])');
    const passwordMinLenghtRegex = new RegExp('^.{8,}$');
    const passwordNumberRegex = new RegExp('[0-9]');
    const passwordLetterRegex = new RegExp('[A-Za-z]');
    const especialCaracterRegex = new RegExp('[!@#$&]');
    const completeRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!@#$&]).{8,}$');

    if (passwordUpercaseRegex.test(payload?.newPassword))
      this.uperCasePassword = true;
    else
      this.uperCasePassword = false;

    if (passwordMinLenghtRegex.test(payload?.newPassword))
      this.minLengthPassword = true;
    else
      this.minLengthPassword = false;

    if (passwordNumberRegex.test(payload?.newPassword))
      this.numberPassword = true;
    else
      this.numberPassword = false;

    if (passwordLetterRegex.test(payload?.newPassword))
      this.letterPassword = true;
    else
      this.letterPassword = false;

    if (especialCaracterRegex.test(payload?.newPassword))
      this.especialCaracterPassword = true;
    else
      this.especialCaracterPassword = false;

    if (completeRegex.test(payload?.newPassword))
      this.completeRegex = true;
    else
      this.completeRegex = false;
  }

  //#endregion
}


