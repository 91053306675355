import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';
import { getCrudErrors } from '../../shared/utils/functions';

@Component({
  selector: 'ngx-check-password',
  templateUrl: './check-password.component.html',
  styleUrls: ['./check-password.component.scss'],
})
export class CheckPasswordComponent {

  //#region Constructor

  constructor(
    private readonly dialogRef: NbDialogRef<CheckPasswordComponent>,
    protected readonly formBuilder: FormBuilder,
    protected readonly http: HttpAsyncService,
    protected readonly toast: NbToastrService,
  ) {
    this.formGroup = this.formBuilder.group(
      {
        password: ['', Validators.required],
      },
    );
  }

  //#endregion

  //#region Public Properties

  @Input()
  public label: string = '';

  public isLoading: boolean = false;

  public formGroup: FormGroup;

  public shouldHidePassword = true;

  //#endregion

  //#region Public Methods

  public cancelUpdate(): void {
    this.dialogRef.close();
  }

  public async checkPassword(): Promise<void> {
    if (this.isLoading) return;

    const payload = this.formGroup.getRawValue();

    if (payload.password.length < 6)
      return void this.toast.warning('A senha deve ter no mínimo 6 caracteres', 'Atenção');

    this.isLoading = true;

    const url = environment.api.userPassword.checkPassword;

    const { success, error } = await this.http.post<{ isOk: boolean }>(url, payload);

    this.isLoading = false;

    if (error)
      return void this.toast.danger(getCrudErrors(error)[0], 'Oops...');
    else
      if (success?.isOk) {
        await this.dialogRef.close({
          isOk: success.isOk,
        });
        this.toast.success('Senha correta!', 'Sucesso');
      } else
        this.toast.danger('Senha incorreta!', 'Oops...');
  }

  //#endregion

}
