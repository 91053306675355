import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbInputModule, NbSpinnerModule } from '@nebular/theme';
import { EditPasswordComponent } from './edit-password.component';



@NgModule({
  declarations: [
    EditPasswordComponent,
  ],
  imports: [
    CommonModule,
    NbCardModule,
    NbInputModule,
    NbFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    NbButtonModule,
    ReactiveFormsModule,
    NbSpinnerModule,
    NbIconModule,
  ],
})
export class EditPasswordModule { }
