import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';

export namespace CustomValidators {
  export function mustMatch(controlName: string, matchingControlName: string): (formGroup: FormGroup) => ValidationErrors | null {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch)
        return null;

      if (control.value !== matchingControl.value)
        matchingControl.setErrors({ mustMatch: true });
      else
        matchingControl.setErrors(null);

      return null;
    };
  }

  export function empty(formValue: AbstractControl) {
    const value = formValue.value as string;
    if(!value.trim())
      return { empty: true };
    else
      return null;
  }

  export function onlyNumbers(formValue: AbstractControl) {
    const value = formValue.value as string;
    const regex = new RegExp('^(0|[1-9][0-9]*)$');
    if(regex.test(value))
      return { onlyNumbers: true };
    else
      return null;
  }

  export function hasNumber(formValue: AbstractControl) {
    const value = formValue.value as string;
    const regex = new RegExp('([0-9])');
    if (regex.test(value))
      return {hasNumber: true};
    else
      return null;
  }

  export function hasSpecialCaracter(formValue: AbstractControl) {
    const value = formValue.value.split(/\s+/).join('') as string;
    const regex = new RegExp(/^[a-záàâãéèêíïóôõöúçñ ]+$/i);

    if (!regex.test(value))
      return { hasSpecialCharacters: true };
    else
      return null;
  }
}
