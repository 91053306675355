<div class="col-12">
  <nb-card [nbSpinner]="isLoading">
    <nb-card-header style="border: 0;">
      {{ isFirstAcess ? 'Este é seu primeiro acesso, crie uma nova' : 'Redefinir' }} senha
    </nb-card-header>

      <nb-card-body nbspinner class="d-flex flex-column align-items-center col-12">
        <form [formGroup]="formGroup">
          <div class="w-100 d-flex flex-wrap">
            <div class="w-100 col-12 col-md-6">

              <label class="ml-3">Senha {{isFirstAcess ? 'provisória' : 'atual'}}</label>

              <nb-form-field class="col-12">
                <input class="input"
                      [type]="shouldHideCurrentPassword ? 'password' : 'text'" nbInput fullWidth
                      formControlName="currentPassword"
                      fullWidth
                      nbInput
                      shape="rectangle" maxlength="32">
                    <button type="button" nbSuffix nbButton ghost (click)="shouldHideCurrentPassword = !shouldHideCurrentPassword">
                      <nb-icon [icon]="!shouldHideCurrentPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva">
                      </nb-icon>
                    </button>
              </nb-form-field>

              <label class="mt-2 ml-3">Nova senha</label>

              <nb-form-field class="col-12">
                <input class="input"
                      formControlName="newPassword"
                      (ngModelChange)="validateNewPassword()"
                      fullWidth
                      nbInput shape="rectangle"
                      [type]="shouldHideNewPassword ? 'password' : 'text'" nbInput fullWidth maxlength="32">
                      <button type="button" nbSuffix nbButton ghost (click)="shouldHideNewPassword = !shouldHideNewPassword">
                        <nb-icon [icon]="!shouldHideNewPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva">
                        </nb-icon>
                      </button>
              </nb-form-field>

              <label class="mt-2 ml-3">Confirmar senha</label>

              <nb-form-field class="col-12">
                <input class="input"
                      formControlName="confirmPassword"
                      (ngModelChange)="validateEqualsPassword()"
                      fullWidth
                      nbInput shape="rectangle"
                      [type]="shouldHideConfirmPassword ? 'password' : 'text'" nbInput fullWidth maxlength="32">
                      <button type="button" nbSuffix nbButton ghost (click)="shouldHideConfirmPassword = !shouldHideConfirmPassword">
                        <nb-icon [icon]="!shouldHideConfirmPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva">
                        </nb-icon>
                      </button>
              </nb-form-field>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
              <label>Sua senha deve possuir</label>

              <div class="d-flex span"
                  [ngClass]="{'success': uperCasePassword,
                               'error': !uperCasePassword}">
                               <nb-icon class="regex-icon" [icon]="uperCasePassword? 'checkmark-outline' : 'close-outline'">
                              </nb-icon>
                  Pelo menos 1 caractere maiúsculo e 1 minúsculo
              </div>

              <div class="d-flex span"
                  [ngClass]="{'success': minLengthPassword,
                               'error': !minLengthPassword}">
                               <nb-icon class="regex-icon" [icon]="minLengthPassword? 'checkmark-outline' : 'close-outline'">
                              </nb-icon>
                  Pelo menos 8 caracteres
              </div>


              <div class="d-flex span"
                  [ngClass]="{'success': numberPassword,
                              'error': !numberPassword}">
                              <nb-icon class="regex-icon" [icon]="numberPassword? 'checkmark-outline' : 'close-outline'">
                              </nb-icon>
                  Pelo menos 1 número
              </div>

              <div class="d-flex span"
                  [ngClass]="{'success': letterPassword,
                              'error': !letterPassword}">
                              <nb-icon class="regex-icon" [icon]="letterPassword? 'checkmark-outline' : 'close-outline'">
                              </nb-icon>
                  Pelo menos 1 letra
              </div>

              <div class="d-flex span"
                  [ngClass]="{'success': especialCaracterPassword,
                              'error': !especialCaracterPassword}">
                              <nb-icon class="regex-icon" [icon]="especialCaracterPassword? 'checkmark-outline' : 'close-outline'">
                              </nb-icon>
                  Pelo menos 1 caractere especial
              </div>

              <div class="d-flex span"
                  [ngClass]="{'success': equalsPassword,
                           'error': !equalsPassword}">
                            <nb-icon class="regex-icon" [icon]="equalsPassword? 'checkmark-outline' : 'close-outline'">
                            </nb-icon>
                  As senhas devem ser iguais
              </div>

                <div class="mt-3 ml-5">
                  <button
                        [disabled]="!completeRegex || !equalsPassword"
                        nbButton
                        shape="round"
                        size="small"
                        class="btn btn-default btn-ok ml-2 col-8"
                        (click)="resetPassword()">
                        {{isFirstAcess? 'Salvar Nova senha' : 'Confirmar edições'}}
                  </button>
                  <button
                        *ngIf="!isFirstAcess"
                        nbButton
                        shape="round"
                        size="small"
                        outline
                        class="mt-2 ml-2 btn-default col-8"
                        (click)="close()">
                        Cancelar
                  </button>
                </div>
            </div>
          </div>
        </form>
      </nb-card-body>
  </nb-card>
</div>
